// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-catalogo-expresso-instalado-index-js": () => import("./../../../src/pages/catalogo-expresso-instalado/index.js" /* webpackChunkName: "component---src-pages-catalogo-expresso-instalado-index-js" */),
  "component---src-pages-catalogo-expresso-mobile-index-js": () => import("./../../../src/pages/catalogo-expresso-mobile/index.js" /* webpackChunkName: "component---src-pages-catalogo-expresso-mobile-index-js" */),
  "component---src-pages-catalogo-impresso-index-js": () => import("./../../../src/pages/catalogo-impresso/index.js" /* webpackChunkName: "component---src-pages-catalogo-impresso-index-js" */),
  "component---src-pages-catalogo-personalizado-index-js": () => import("./../../../src/pages/catalogo-personalizado/index.js" /* webpackChunkName: "component---src-pages-catalogo-personalizado-index-js" */),
  "component---src-pages-clientes-index-js": () => import("./../../../src/pages/clientes/index.js" /* webpackChunkName: "component---src-pages-clientes-index-js" */),
  "component---src-pages-contato-index-js": () => import("./../../../src/pages/contato/index.js" /* webpackChunkName: "component---src-pages-contato-index-js" */),
  "component---src-pages-desenhos-e-ilustracoes-tecnicas-index-js": () => import("./../../../src/pages/desenhos-e-ilustracoes-tecnicas/index.js" /* webpackChunkName: "component---src-pages-desenhos-e-ilustracoes-tecnicas-index-js" */),
  "component---src-pages-empresa-index-js": () => import("./../../../src/pages/empresa/index.js" /* webpackChunkName: "component---src-pages-empresa-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-orcamento-index-js": () => import("./../../../src/pages/orcamento/index.js" /* webpackChunkName: "component---src-pages-orcamento-index-js" */)
}

